import React, { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useCanvas, ScrollScene } from '@14islands/r3f-scroll-rig'
// import mergeRefs from 'react-merge-refs'

import WaterTextMesh from 'components/three/water-text-mesh'

const WaterText = forwardRef(({ as = 'div', children, text, className, lerp, hidden, ...propsOuter }, ref) => {
  const el = useRef()
  const Element = as

  const updateMesh = useCanvas(
    <ScrollScene
      resizeDelay={1000}
      el={el}
      lerp={lerp}
      {...propsOuter}
      hiddenStyle={{ color: 'transparent', opacity: 1 }}
    >
      {props => (
        <WaterTextMesh hidden={hidden} color="white" {...props}>
          {text || children}
        </WaterTextMesh>
      )}
    </ScrollScene>,
  )

  useEffect(() => {
    updateMesh({ hidden })
  }, [hidden])

  return (
    <Element ref={el} className={className}>
      {children || text}
    </Element>
  )
})

WaterText.displayName = 'WaterText'

WaterText.propTypes = {
  as: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  lerp: PropTypes.number,
  hidden: PropTypes.bool,
}

export default WaterText
