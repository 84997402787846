import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { RichText } from 'prismic'

import WaterText from 'components/ui/water-text'

import * as s from './HeroText.module.css'

const cn = classNames.bind(s)

const HeroText = ({ className, text, description }) => {
  return (
    <div className={cn('wrapper', className)}>
      <WaterText className={cn('text')} text={text} lerp={0.08} />
      {description?.richText && (
        <WaterText className={cn('description')} text={description.text}>
          {RichText.render(description.richText)}
        </WaterText>
      )}
    </div>
  )
}

HeroText.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.object,
}

export default HeroText
