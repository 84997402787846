import React, { useRef, useEffect } from 'react'
import { useFrame } from '@react-three/fiber'
import { useViewportScroll } from 'framer-motion'
import PropTypes from 'prop-types'
import lerp from '@14islands/lerp'

import { WebGLText } from '@14islands/r3f-scroll-rig/stdlib'

import './WaterMaterial'

const FONT = '/fonts/aften/aften_screen.woff'

const WaterTextMesh = ({ children, scale, scrollState, hidden, ...props }) => {
  const { scrollY } = useViewportScroll()
  const textIsReady = useRef(false)
  const material = useRef()
  const wrapper = useRef()

  // increase effect while scrolling
  useEffect(
    () =>
      scrollY.onChange(val => {
        if (!material.current) return
        material.current.effect = Math.min(5, material.current.effect + Math.abs(scrollY.getVelocity()) * 0.0005)
      }),
    [],
  )

  useFrame(({ clock }, delta) => {
    if (!material.current) return
    if (!scrollState.inViewport || !textIsReady.current) {
      return
    }

    // time - keep all materials in synx
    material.current.time = clock.getElapsedTime() * 3

    // decrease effect over time
    material.current.effect = Math.max(1, material.current.effect - 0.05)

    // animate in
    material.current.opacity = lerp(material.current.opacity, hidden ? 0 : 1, hidden ? 0.2 : 0.01, delta)
    wrapper.current.scale.x = lerp(wrapper.current.scale.x, 1, 0.01, delta)
    wrapper.current.scale.y = lerp(wrapper.current.scale.y, 1, 0.01, delta)
  })

  return (
    <group ref={wrapper} scale={[0.8, 0.8, 1]}>
      <WebGLText
        font={FONT}
        scale={scale}
        maxWidth={scale.width * 1.03}
        fontOffsetY={-0.01}
        overflowWrap="break-word"
        onSync={() => {
          textIsReady.current = true
        }}
        {...props}
      >
        {children}
        <waterTextMaterial ref={material} depthTest={false} opacity={0} transparent />
      </WebGLText>
    </group>
  )
}

WaterTextMesh.propTypes = {
  scale: PropTypes.object,
  scrollState: PropTypes.object,
  hidden: PropTypes.bool,
}

export default WaterTextMesh
