import { extend } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei/core/shaderMaterial'
import { Color } from 'three'

const WaterTextMaterial = shaderMaterial(
  { time: 0, effect: 0, opacity: 0, color: new Color(1.0, 1.0, 1.0) },
  // vertex shader
  `
    uniform float time;
    uniform float effect;

    varying vec2 vUv;

    void main() {
      vUv = uv;

      vec3 p = vec3(position.x, position.y, position.z);

      float frequency1 = .01; //0.035;
      float amplitude1 = 5.0; //20.0;
      float frequency2 = 0.025;
      float amplitude2 = 0.07; //70.0;

      // Oscillate vertices up/down
      p.y += (sin(p.x * frequency1 + time) * 0.5 + 0.5) * amplitude1 * effect;

      // Oscillate vertices inside/outside
      p.z += (sin(p.x * frequency2 + time) * 0.5 + 0.5) * amplitude2 * effect;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(p, 1.0);
    }
  `,
  // fragment shader
  `
    uniform float time;
    uniform vec3 color;
    uniform float opacity;
    varying vec2 vUv;
    void main() {
      gl_FragColor.rgba = vec4(color, opacity);
    }
  `,
)

extend({ WaterTextMaterial })

export default WaterTextMaterial
